import "../css/index.css"

function IndexContent() {
  return(
    <div className="index-content">

    </div>
  )
}

export default IndexContent
